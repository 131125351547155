// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")
import "bootstrap";

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})
$(document).on('turbolinks:load', function() {
	$('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
        $(this).toggleClass('active');
    });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("trix")
require("@rails/actiontext")

//Flatpickr date javascript
import flatpickr from "flatpickr"
require("flatpickr/dist/themes/dark.css");
document.addEventListener("turbolinks:load", () => {
	flatpickr("[data-behaviour='flatpickr']", {
		altInput: true,
		altFormat: "F j Y",
		dateFormat: "Y-m-d",
	})
})

$( document ).on('turbolinks:load', function() {
  	$('[data-toggle="tooltip"]').click(function () {
  		
  		var dummy = document.createElement("input");
  		document.body.appendChild(dummy);

  		let string = $(this).attr('value');
  		dummy.setAttribute("id", "dummy_id");
  		document.getElementById("dummy_id").value=string;
  		dummy.select();
  		dummy.setSelectionRange(0, 99999); /*For mobile devices*/
  		document.execCommand("copy");
  		document.body.removeChild(dummy);

		setTimeout(function() {
		    $('[data-toggle="tooltip"]').tooltip('hide');
		}, 500);
    });
});